<template>
  <!-- 防爆云台 -->
  <div class="around qmytex" v-if="elmentList.length">
    <div class="banner_video"  :id="'elm' + elmentList[0].id">
      <img :src="elmentList[0].pcImg" v-if="this.$store.state.pageset.pcshow" />
      <img :src="elmentList[0].phoneImg" v-else />
      <div class="img-con">
        <div class="title">
          <h1>{{ elmentList[0].descList[0].descName }}</h1>
        </div>
      </div>
      <div class="dec" v-animate="'queue-bottom'">
        <div class="dec-par">
          <div class="dec-par-item" v-for="index in 4" :key="index">
            <p class="dec-par-item-title">
              {{ elmentList[0].descList[index].descName }}
            </p>
            <p class="dec-par-item-dec">
              {{ elmentList[0].descList[index + 4].descName }}
            </p>
          </div>
        </div>
        <div class="scroll-wrap">
          <div class="remark">
            本页面视频中出现的产品仅供参考，产品的外观、配置、型号等细节可能有所差异，具体请以当地实际交付为准。
          </div>
        </div>
      </div>
    </div>
    <div class="qmytex-content">
      <div class="content-one"  :id="'elm' + elmentList[1].id">
        <img :src="elmentList[1].pcImg" v-if="$store.state.pageset.pcshow" />
        <img :src="elmentList[1].phoneImg" v-else />
        <div class="dec" v-animate="'queue-bottom'">
          <div class="dec-left">
            <p class="p2">
              {{ elmentList[1].descList[0].descName }}
            </p>
          </div>
        </div>
      </div>
      <!-- 左右排列参数 -->
      <div class="content-tree" v-if="this.$store.state.pageset.pcshow">
        <div v-for="index in 4" :key="index"  :id="'elm' + elmentList[index + 1].id">
          <div class="fun-item" v-if="index % 2 === 0">
            <div class="fun-left">
              <img :src="elmentList[index + 1].pcImg" />
            </div>
            <div class="fun-right">
              <div class="fun-right-dec" v-animate="'queue-bottom'">
                <p class="p1">
                  {{ elmentList[index + 1].descList[0].descName }}
                </p>
                <p class="p2">
                  {{ elmentList[index + 1].descList[1].descName }}
                </p>
              </div>
            </div>
          </div>
          <div class="fun-item" v-else>
            <div class="fun-left">
              <div class="fun-right-dec" v-animate="'queue-bottom'">
                <p class="p1">
                  {{ elmentList[index + 1].descList[0].descName }}
                </p>
                <p class="p2">
                  {{ elmentList[index + 1].descList[1].descName }}
                </p>
              </div>
            </div>
            <div class="fun-right">
              <img :src="elmentList[index + 1].pcImg" />
            </div>
          </div>
        </div>
      </div>
      <div class="content-tree" v-else>
        <div v-for="index in 4" :key="index">
          <div>
            <img :src="elmentList[index + 1].phoneImg" width="100%" height="100%" />
          </div>
          <div class="fun-dec">
            <div class="fun-right-dec" v-animate="'queue-bottom'">
              <p class="p1">{{ elmentList[index + 1].descList[0].descName }}</p>
              <p class="p2">{{ elmentList[index + 1].descList[1].descName }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else v-loading="true"></div>
</template>
<script>
import QMYTEx from "./QMYTEx.js";

export default QMYTEx;
</script>
<style scoped rel="stylesheet/stylus" lang="scss">
@import "~@/assets/style/common.scss";
@import "../common.scss";
@import "QMYTEx.scss";
</style>
