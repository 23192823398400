
export default {
    data() {
        return {
            // elmentList:
            //     [
            //         {
            //             pcImg: require('./images/pc/banner1.jpg'),
            //             phoneImg: require('./images/mobile/banner1.jpg'),
            //             descList: [
            //                 {
            //                     descName: '防爆云台',
            //                     sort: 1
            //                 }, {
            //                     descName: 'Ex d IIC T6 Gb',
            //                     sort: 2
            //                 }, {
            //                     descName: 'IP66',
            //                     sort: 3
            //                 }, {
            //                     descName:'-30°C~+60°C' ,
            //                     sort: 4
            //                 }, {
            //                     descName: '≤0.1°',
            //                     sort: 5
            //                 },
            //                 {
            //                     descName: '防爆等级',
            //                     sort: 6
            //                 }, {
            //                     descName: '防护等级',
            //                     sort: 7
            //                 }, {
            //                     descName: '工作温度',
            //                     sort: 8
            //                 }, {
            //                     descName: '位置精度',
            //                     sort: 9
            //                 }],
            //              type: 'image',
            //             sort: 1
            //         },
            //         {
            //             pcImg: require('./images/pc/banner2.jpg'),
            //             phoneImg: require('./images/mobile/banner2.jpg'),
            //             descList: [ {
            //                     descName: '主要配备于轮式巡检机器人、履带式巡检机器人等防爆移动平台，是一种隔爆式防爆云台，搭载可见光摄像机、补光灯、红外热成像仪、激光甲烷遥测仪、激光测距仪等设备，具备环境观察、表计读数识别、跑冒滴漏勘察、红外测温、甲烷激光探测、激光测距等能力。',
            //                     sort: 1
            //                 },],
            //              type: 'image',
            //             sort: 2
            //         },
            //         {
            //             pcImg: require('./images/pc/banner3.jpg'),
            //             phoneImg: require('./images/mobile/banner3.jpg'),
            //             descList: [
            //                 {
            //                     descName: '高清图像输出',
            //                     sort: 1
            //                 }, {
            //                     descName: '能通过以太网输出1080P可见光图像、红外测温图像',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 3
            //         },
            //         {
            //             pcImg: require('./images/pc/banner4.jpg'),
            //             phoneImg: require('./images/mobile/banner4.jpg'),
            //             descList: [
            //                 {
            //                     descName: '信息反馈',
            //                     sort: 1
            //                 }, {
            //                     descName: '具有云台工作状态和位置信息反馈功能',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 4
            //         },
            //         {
            //             pcImg: require('./images/pc/banner5.jpg'),
            //             phoneImg: require('./images/mobile/banner5.jpg'),
            //             descList: [
            //                 {
            //                     descName: '全天候检测',
            //                     sort: 1
            //                 }, {
            //                     descName: '具有环境光强感知和补光灯强度调节功能，配备雨刷器，可实现全天候巡逻检测',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 5
            //         },
            //         {
            //             pcImg: require('./images/pc/banner6.jpg'),
            //             phoneImg: require('./images/mobile/banner6.jpg'),
            //             descList: [
            //                 {
            //                     descName: '高精控制',
            //                     sort: 1
            //                 }, {
            //                     descName: '采用伺服电机驱动，运行平稳，控制精度高',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 6
            //         }
            //     ],
                elmentList: [],
        }
    },
    created() {
        this.$store.dispatch("pageset/getbannerIndex", 0);
        document.documentElement.style.setProperty('--var-primary-color', '#fff');
                // 获取当前页面的配置信息
                this.$store.dispatch('pageset/getPageInfo', this.$route.query.pageId).then(() => {
                    this.elmentList = this.$store.state.pageset.sourceData.elmentList;
                });
    },
    methods: {
        retention() {
            this.$router.push('/reservation');
        },
        openPdf() {
            this.$router.push('/pdfView')
        },

    }
}